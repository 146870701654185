<template>
  <div>
    <div class="workdayShopD">
      <div class="workdayShopD_box">
        <div class="workdayShopD_di">
          <div>
            <!-- <img src="../../assets/img/din.png" alt=""> -->
            <!-- <h2>碳鱼烧烤（丰台分店）</h2> -->
          </div>
          <div>
            <a @click="xinZen">新增</a>
            <a @click="cancel_surface">删除</a>
            <a @click="amesssnd()">修改</a>
          </div>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" height="450"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="200"> </el-table-column>

        <el-table-column prop="role_name" label="角色" width="500">
        </el-table-column>
        <el-table-column prop="cat_name" label="菜系" width="500">
          <template slot-scope="scope">
            <div>
                 <span>{{scope.row.cat_name}}</span>
              <!-- <el-input disabled v-model="scope.row.cat_name" style=""></el-input> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="提成比例（%）" width="500">
          <template slot-scope="scope">
            <div>
              <!-- <el-input disabled v-model="scope.row.rate" placeholder="1" :value="scope.row.rate+''"></el-input> -->

              <span>{{scope.row.rate}}</span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="entrepot" label="工作时间" width="695">
          <template slot-scope="scope">
            <el-time-picker disabled is-range v-model="scope.row.value2" range-separator="至" format="HH:mm"
              :start-placeholder="scope.row.kaishgi" :end-placeholder="scope.row.jieshu" placeholder="选择时间范围">
            </el-time-picker>
          </template>
        </el-table-column> -->

      </el-table>
    </div>
    <div class="tishi">提示： 若员工轮休采取轮休制，则设置周一到周日均为工作日</div>





    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false">
      <div class="working_days">
        <h2><span></span>提成设置</h2>
        <div>
          <p><span>*</span>选择角色:</p>
          <el-cascader v-model="role_value" :options="role" placeholder="请选择角色" :props="defaultProps"></el-cascader>
        </div>


        <div>
          <p><span>*</span>选择菜系:</p>

          <el-select v-model.trim="workday_value" multiple placeholder="请选择">
            <el-option v-for="item in workday" :key="item.cat_id" :label="item.cat_name" :value="item.cat_id">
            </el-option>
          </el-select>
        </div>
        <div>

          <p><span>*</span>提成比例: </p>
          <el-input v-model="days" placeholder="请输入提成比例（%）"></el-input>
         
        </div>
        <div>
        </div>
        <el-button @click="New_Working()" type="danger" round v-if="bao">保存</el-button>
        <el-button @click="amend_Working()" type="danger" round v-if="xiu">修改</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
  import {
    query_workday_set_list,
    update_workday_set,
    delete_workday_set,
    insert_workday_set,
    insert_rule_comm,
    query_rule_comm_list,
    query_category_info_list,
    delete_rule_comm,
    update_rule_comm
  } from '@api/pulic'

  import {
    insert_role_group_info,
    query_role_group_info_list,
    delete_role_group_info,
    insert_role_info,
    query_dept_staff_tree_aim_not,
    set_staff_role_rel,
    query_user_list_of_role,

  } from '../../api/store'

  export default {
    name: 'Workday',
    data() {
      return {
        bao: true,
        xiu: false,
        kaishi: '',
        jieshuS: '',
        tableData: [

        ],
        defaultProps: {
          multiple: true,
          children: 'role_list',
          label: 'role_name',
          value: 'role_id',
          emitPath:false
        },
        drawer: false,
        // 角色
        role: [],
        roleS: {}, // 角色
        role_value: '', // 选择角色的value
        //  选择工作日
        workday_S: {},
        workday_value: '',
        workday: [{
          value: '1',
          label: '周一'
        }, {
          value: '2',
          label: '周二'
        }, {
          value: '3',
          label: '周三'
        }, {
          value: '4',
          label: '周四'
        }, {
          value: '5',
          label: '周五'
        }, {
          value: '6',
          label: '周六'
        }, {
          value: '7',
          label: '周日'
        }],
        // 休息天数
        days: '',
        // 选择时间
        time_S: [new Date(), new Date()],
        time_sss: '',

        P_id: '',
        gongZr: '',

        sahngBang: [],
        list_set: '',
        AddKit: {
          role_value: '',
          workday_value: [],
          days: '',
          time_S: ''
        }
      }
    },
    created() {
      // query_rule_comm()
      // this.P_id = this.$route.params.sss

      // sessionStorage.setItem('P_id', this.$route.params.sss)
      // console.log(sessionStorage.getItem('P_id'))

      // // 加载方案列表
      this.liebiao()
      this.getreolList()


      query_category_info_list({
        data: {
          // ent_id:self.$store.state.ent_id,
          // ent_id: this.$store.state.ent_id,
          ent_id: this.$store.state.ent_id,
          active: '1'
        }
      }).then((res) => {
        console.log('查询的信息---查询分类', res);
        // 菜品的分类
        this.workday = res.body.data

      })



      query_rule_comm_list({
        data: {
          ent_id: this.$store.state.ent_id,
          // active: '1'
        }
      }).then(res => {
        if (res.code == '200') {
          console.log(res.body.data, '角色列表')
          for (const i in res.body.data) {
            res.body.data[i].role_name = res.body.data[i].role_group_name
          }


          this.role = res.body.data

        }
      })
    },
    watch: {
      drawer(val) {
        if (!val) {
          this.emit()
        }
      },

    },
    methods: {
      getreolList() {
        query_role_group_info_list({
          data: {
            ent_id: this.$store.state.ent_id,
            active: '1'
          }
        }).then(res => {
          if (res.code == '200') {
            console.log(res.body.data, '角色列表')
            for (const i in res.body.data) {
              res.body.data[i].role_name = res.body.data[i].role_group_name
            }


            this.role = res.body.data

          }
        })
      },
      emit() {

        this.AddKit = {
          role_value: '',
          workday_value: [],
          days: '',
          time_S: ''
        }
        this.role_value = ''
        this.workday_value = []
        this.days = ''
        this.time_S = ''
      },
      xinZen() {
        this.drawer = true
        this.bao = true
        this.xiu = false
      },

      amesssnd() {
        if (this.list_set.length != 1) {
          this.$message({
            message: '您请选择一个设置进行修改',
            type: 'waring'
          })
        } else {
          console.log(this.list_set)
          // console.log(this.list_set[0].set_idA)
          
          // if(this.list_set[0].set_idA){
            
          // }
          // if (this.list_set[0].set_idA&&this.list_set[0].set_idA.indexOf(',')) {
            this.workday_value = this.list_set[0].cat_id.split(',')

          // } else {

          //   this.role_value = [this.list_set[0].set_idA]
          // }
          this.role_value = this.list_set[0].role_id.split(',')//  ssssssssssssssssssssssssssssssssssssssssssssssssss 有问题
          // this.workday_value = this.list_set[0].value1.split(',');
          this.days = this.list_set[0].rate

          this.$nextTick(function () {
            this.drawer = true
            this.bao = false
            this.xiu = true
          })



        }



      },


      amend_Working() {

      var that = this;

        if (!that.workday_value) {

          this.$message({
            message: '请选择菜系',
            type: 'error',

          })

          return

        }
        if (!that.role_value) {
          this.$message({
            message: '请选择角色',
            type: 'error',

          })
          return
        }
        if (!that.days) {
          this.$message({
            message: '请输入提成比例',
            type: 'error',

          })
          return

        }



        let arr = this.role_value.flat(Infinity)
        let arr2 = []

        for (const i in arr) {
          if (arr[i] != undefined) {
            arr2.push(arr[i])
          }
        }
        let arr1 = this.workday_value.flat(Infinity)
        let arr3 = []
        //
        for (const i in arr1) {
          if (arr1[i] != undefined) {
            arr3.push(arr1[i])
          }
        }


          insert_rule_comm({
          data: {
            ent_id: that.$store.state.ent_id, // 企业
            user_id:that.$store.state.user_id,
            role_id: arr2.join(','),
            cat_id: arr3.join(','), //
            rate: that.days, //每周休息天数
          }
        }).then(res => {
          console.log('渲染方案列表', res)
          if (res.code == 200) {
            that.emit()
            that.drawer = false
          }
          this.liebiao()
        })
        
      },

      handleSelectionChange(val) {
        this.list_set = val
      },

      // 删除列表
      cancel_surface() {



        let arr=[]
        for (const i in this.list_set) {
         arr.push(this.list_set[i].role_id)
        }
        let param = {
          ent_id: this.$store.state.ent_id,
          role_id: arr.join(','),
          user_id:this.$store.state.user_id
        }
        delete_rule_comm({
          data: param
        }).then(res => {
          this.liebiao()
        })
      },


      New_Working() {
        var that = this;

        if (!that.workday_value) {

          this.$message({
            message: '请选择菜系',
            type: 'error',

          })

          return

        }
        if (!that.role_value) {
          this.$message({
            message: '请选择角色',
            type: 'error',

          })
          return
        }
        if (!that.days) {
          this.$message({
            message: '请输入提成比例',
            type: 'error',

          })
          return

        }



        let arr = this.role_value.flat(Infinity)
        let arr2 = []

        for (const i in arr) {
          if (arr[i] != undefined) {
            arr2.push(arr[i])
          }
        }
        let arr1 = this.workday_value.flat(Infinity)
        let arr3 = []
        //
        for (const i in arr1) {
          if (arr1[i] != undefined) {
            arr3.push(arr1[i])
          }
        }

        console.log(this.workday_value)
        insert_rule_comm({
          data: {
            ent_id: that.$store.state.ent_id, // 企业
            user_id:that.$store.state.user_id,
            role_id: arr2.join(','),
            cat_id: arr3.join(','), //
            rate: that.days, //每周休息天数
          }
        }).then(res => {
          console.log('渲染方案列表', res)
          if (res.code == 200) {
            that.emit()
            that.drawer = false
          }
          this.liebiao()
        })

      },

      // 渲染列表
      liebiao() {
        query_rule_comm_list({
          data: {
            ent_id: this.$store.state.ent_id,
            // plan_id: this.P_id,
          }
        }).then(res => {
          console.log(res)
          let arr = res.body.data
          this.tableData = arr
          // arr.forEach(item => {
          //   let objS = {
          //     serial: item.role_name, // 角色
          //     days: item.days_rest, // 休息天数
          //     value1: item.workday,
          //     day: item.days_rest,
          //     kaishgi: item.time_start,
          //     jieshu: item.time_end,
          //     set_idA: item.set_id
          //   }
          //   this.tableData.push(objS)
          // })
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  .el-main {

    background: #f2f6fc;
  }

  .workdayShopD {
    background: #fff;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
    padding: 0 0 200px 0;
    box-sizing: border-box;
    border-radius: 16px;
    margin: 30px  0;
    .tishi{
      margin-bottom: 20px;
    }
    .workdayShopD_box {
      .workdayShopD_di {
        display: flex;
        height: 80px;
        background: #fff;
        border-radius: 15px 15px 0px 0px;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        box-sizing: border-box;

        h2 {
          font-size: 18px;
          font-family: Adobe Heiti Std;
          font-weight: 600;
          color: #333333;
          line-height: 30px;
        }

        img {
          width: 23px;
          height: 30px;
          margin-right: 16px;
        }

        div {
          display: flex;

          a {
            width: 100px;
            height: 44px;
            border: 1px solid #FF0000;
            border-radius: 10px;
            display: block;
            font-size: 16px;
            color: #FF4141;
            line-height: 44px;
            text-align: center;
            margin-left: 20px;
          }
        }
      }
    }
  }

  .working_days {
    padding: 10px;

    h2 {
      position: relative;
      color: #333;
      font-size: 20px;
      font-weight: 400;
      padding-left: 12px;
      margin-top: 20px;
      margin-bottom: 40px;

      span {
        display: block;
        position: absolute;
        left: 0;
        top: -7px;
        width: 6px;
        height: 32px;
        background: #FF0000;
        border-radius: 3px;
      }
    }

    div {
      margin-bottom: 10px;
      display: flex;

      &>p {
        font-size: 16px;
        color: #333;
        width: 130px;
        text-align: right;
        position: relative;
        margin: 10px;

        span {
          color: #FF4141;
          font-size: 16px;
          margin-right: 4px;
        }
      }
    }
  }
</style>

<style>
  .workdayShopD .el-input.is-disabled .el-input__inner {
    color: black;
  }


  .workdayShopD .el-input__inner {
    color: black;


  }

  .el-range-editor.is-disabled input {
    color: black !important;
  }

  .workdayShopD .el-range-editor.is-disabled {
    color: black !important;
  }

  .workdayShopD .el-range-editor.is-disabled .el-range-separator {
    color: black !important;
  }
</style>